import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
const IconLogo = () => (
  <div id="logo">
    <StaticImage
      className="img"
      src="../../images/logo.png"
      quality={100}
      formats={['AUTO', 'WEBP', 'AVIF']}
      alt="logo"
    />
  </div>
);

export default IconLogo;
